<template>
  <div>
    <loader v-if="isFetching" size="xxs" :loader-image="false" />
    <div v-else class="w-full flex flex-col gap-8 p-5">
      <div class="flex justify-start items-center gap-6 -ml-3">
        <div class="border-r border-romanSilver h-6 flex">
          <back-button label="Back" @onClick="$router.back()" variant="secondary" />
        </div>
        <h1 class="text-xl text-left font-extrabold">Leave</h1>
        <breadcrumb :items="breadcrumbs" />
      </div>

      <div class="flex flex-col gap-4">
        <div class="flex flex-col justify-center items-start gap-4 my-4">
          <p class="font-semibold text-sm text-darkPurple">
            {{ leavePlan.leaveYear}} Leave Plan for
          </p>
          <div class="w-full flex justify-between items-center">
            <div class="flex justify-start items-start gap-2">
              <div>
                <span v-if="false" class="flex justify-center items-center w-12 h-12">
                  <img src="" class="w-12 h-12 rounded" alt="profile photo" />
                </span>
                <div v-else class="flex justify-center items-center w-12 h-12 rounded border">
                  <span class="text-blueCrayola text-center font-semibold text-lg p-2">
                    {{ $getInitials(`${employeeInfo.fname} ${employeeInfo.lname}`) }}
                  </span>
                </div>
              </div>
              <div class="flex flex-col justify-start gap-1">
                <span class="font-semibold text-darkPurple text-base">
                  {{ employeeInfo.fname }} {{ employeeInfo.lname }}
                </span>
                <span class="font-normal text-romanSilver text-xs uppercase">
                  {{ employeeInfo.userDesignation && employeeInfo.userDesignation.name }}
                </span>
              </div>
            </div>
            <div class="rounded-md p-2 text-sm w-44 text-center capitalize"
              :class="{
                'bg-green-100 text-mediumSeaGreen': leavePlan.status === 'approved',
                'bg-yellow-100 text-carrotOrange': leavePlan.status === 'pending',
                'bg-red-100 text-flame': leavePlan.status === 'disapproved',
              }"
            >
              {{
                leavePlan.status === 'approved' ? 'Tentatively Approved' :
                leavePlan.status === 'disapproved' ? 'Disapproved' : 'Pending'
              }}
            </div>
          </div>
        </div>

        <div class="grid grid-cols-3 gap-x-8 gap-y-3">
          <div class="w-full flex flex-col justify-start items-start gap-2 pb-1">
            <span class="text-sm text-jet font-normal">Total Leave Balance</span>
            <div class="w-full bg-cultured border border-romanSilver rounded-md py-2 px-4">
              <span class="text-romanSilver text-base font-normal">{{ leavePlan.leaveBalance }} Day(s)</span>
            </div>
          </div>
          <div class="w-full flex flex-col justify-start items-start gap-2 pb-1">
            <span class="text-sm text-jet font-normal">{{ leavePlan.leaveYear }} Leave Days</span>
            <div class="w-full bg-cultured border border-romanSilver rounded-md py-2 px-4">
              <span class="text-romanSilver text-base font-normal">{{ leavePlan.leaveDays }} Days</span>
            </div>
          </div>
          <div class="w-full flex flex-col justify-start items-start gap-2 pb-1">
            <span class="text-sm text-jet font-normal">{{ leavePlan.leaveYear - 1 }} Carry Forward Balance</span>
            <div class="w-full bg-cultured border border-romanSilver rounded-md py-2 px-4">
              <span class="text-romanSilver text-base font-normal">
                {{ leavePlan.carriedForwardBalance ? leavePlan.carriedForwardBalance : 0 }} Days
              </span>
            </div>
          </div>
          <div class="w-full flex flex-col justify-start items-start gap-2 pb-1">
            <span class="text-sm text-jet font-normal">Total Planned Leave (Days)</span>
            <div class="w-full bg-cultured border border-romanSilver rounded-md py-2 px-4">
              <span class="text-romanSilver text-base font-normal">{{ leavePlan.totalPlannedLeaveDays }} Days</span>
            </div>
          </div>
          <div class="w-full flex flex-col justify-start items-start gap-2 pb-1">
            <span class="text-sm text-jet font-normal">Total Unplanned Leave (Days)</span>
            <div class="w-full bg-cultured border border-romanSilver rounded-md py-2 px-4">
              <span class="text-romanSilver text-base font-normal">{{ leavePlan.totalUnplannedLeaveDays }} Days</span>
            </div>
          </div>
        </div>

        <c-card class="w-full flex flex-col p-5 pt-0">
          <CardFooter
            printcard
            reloadcard
            @reload="onQueryFn({}, null, reload)"
            @actionModal="onFilter()"
            @sortType="onQueryFn({ sort: $event }, null, getLeaveTypes)"
            @searchResult="onQueryFn({ search: $event }, null, getLeaveTypes)"
          />
        </c-card>

        <c-table
          :headers="headers"
          :items="leavePlanOptions"
          aria-label="annual leave plan table"
          :loading="isFetching"
          :has-number="false"
          class="border-b border-dashed border-romanSilver pb-4"
        >
          <template v-slot:item="{ item }">
            <span v-if="item.categoryId" class="text-darkPurple text-sm">
              {{ item.data.category.name }}
            </span>
            <span v-if="item.details" class="text-darkPurple text-sm">
              {{ item.data.details }}
            </span>
            <span v-if="item.startDate" class="text-darkPurple text-sm">
              {{ $DATEFORMAT(new Date(item.data.startDate), "MMMM dd, yyyy") }}
            </span>
            <span v-if="item.endDate" class="text-darkPurple text-sm">
              {{ $DATEFORMAT(new Date(item.data.endDate), "MMMM dd, yyyy") }}
            </span>
            <span v-if="item.numberOfDays" class="text-darkPurple text-sm">
              {{ item.data.numberOfDays }}
            </span>
          </template>
        </c-table>

        <template v-if="conflictOptions.length">
          <div class="flex flex-col justify-start items-start gap-4 w-full my-4">
            <div class="w-full flex justify-between items-center">
              <p class="font-semibold text-base text-darkPurple">Leave Conflict</p>
              <p class="font-normal text-sm text-darkPurple">View Conflict</p>
            </div>
            <div class="flex flex-col justify-start items-start gap-2 w-full">
              <p class="font-semibold text-sm text-romanSilver">Conflicting with 2 employees with the same job title and the same function.</p>
              <div class="w-full" v-for="item in conflictOptions" :key="JSON.stringify(item)">
                <div class="w-full flex justify-between items-center">
                  <p class="font-semibold text-sm text-darkPurple capitalize">{{ item.fname }} {{ item.lname }}</p>
                  <p class="font-semibold text-sm text-darkPurple">{{ item.totalConflictedDays }} Day(s)</p>
                  <div @click="$router.push({ name: $route.query.mode === 'ess' ? 'EssEmployeeCalendarViewPlan' : 'EmployeeCalendarViewPlan', params: { id: leavePlan.id, conflictId: item.id, startDate: item.conflictedData.startDate } })">
                    <p class="font-semibold text-sm text-flame cursor-pointer hover:underline">View Conflict</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>

      <FilterTable
        v-if="isOpenFilter"
        :filter-data="filterOptions"
        @close="isOpenFilter = false"
        @submit="isOpenFilter = false"
      />
    </div>
  </div>
</template>

<script>
import BackButton from "@scelloo/cloudenly-ui/src/components/back-button"
import Breadcrumb from "@scelloo/cloudenly-ui/src/components/bread-crumb"
import CTable from "@scelloo/cloudenly-ui/src/components/table"
import CCard from "@scelloo/cloudenly-ui/src/components/card"
import CardFooter from "@/components/CardFooter"
import FilterTable from "@/components/FilterTable"

export default {
components: {
    BackButton,
    Breadcrumb,
    CCard,
    CTable,
    CardFooter,
    FilterTable,
  },
  data(){
    return {
      isOpenFilter: false,
      isFetching: false,
      isLoading: false,
      filterOptions: [],
      setQueryTimeout: null,
      queryParams: {},
      leaveYear: null,
      planId: null,
      conflictOptions: [],
      leavePlan: {},
      employeeInfo: {},
      leavePlanOptions: {},
      headers: [
        { title: "leave type", value: "categoryId" },
        { title: "Description", value: "details" },
        { title: "Start Date", value: "startDate" },
        { title: "End Date", value: "endDate" },
        { title: "Total Leave Days", value: "numberOfDays" },
      ],
    }
  },
  computed: {
      breadcrumbs(){
        return [
          {
            disabled: false,
            text: "Leave Plan",
            href: "Leave Plan",
            id: "Leave Plan"
          },
          {
            disabled: false,
            text: `${this.leavePlan.leaveYear} Leave Plan`,
            href: `${this.leavePlan.leaveYear} Leave Plan`,
            id: `${this.leavePlan.leaveYear} Leave Plan`
          },
          {
            disabled: false,
            text: `View ${this.leavePlan.leaveYear} Leave Plan for ${this.employeeInfo.fname} ${this.employeeInfo.lname}`,
            href: `View ${this.leavePlan.leaveYear} Leave Plan for ${this.employeeInfo.fname} ${this.employeeInfo.lname}`,
            id: `View ${this.leavePlan.leaveYear} Leave Plan for ${this.employeeInfo.fname} ${this.employeeInfo.lname}`
          }
        ]
      }
  },
  methods: {
    onQueryFn(queryParams, payload, callback){
      clearTimeout(this.setQueryTimeout);
      this.setQueryTimeout = setTimeout(() => {
        if(queryParams === undefined){
          this.queryParams = {}
          return callback({ ...this.queryParams, ...payload })
        }
        this.queryParams = {...this.queryParams, ...queryParams}
        return callback({ ...this.queryParams, ...payload })
      }, 1000);
    },
    onFilter(){
      this.filterOptions = [
        { header: 'Location(s)', optionsBox: this.locationOptions },
        { header: 'Function(s)', optionsBox: this.functionOptions },
        { header: 'Level(s)', optionsBox: this.levelOptions },
        { header: 'Designation(s)', optionsBox: this.designationOptions },
      ]
      this.isOpenFilter = true
    },
    async getAdminEmployeeAnnualLeave(params){
      this.isFetching = true
      const payload = { planId: this.planId, params }
      await this.$_getAdminEmployeeAnnualLeave(payload).then(({ data }) => {
        this.leavePlan = data.leavePlan
        this.employeeInfo = data.employee
        this.conflictOptions = data.conflicts
        this.leavePlanOptions = data.leavePlan.leavePlanDetails
        this.isFetching = false
      })
    },

    async getManagersEmployeeAnnualLeave(params) {
      this.isFetching = true
      const payload = { planId: this.planId, year: this.leaveYear, params }
      await this.$_getEmployeeAnnualLeavePlanDetails(payload).then(({ data }) => {
        this.leavePlan = data.leavePlan
        this.employeeInfo = data.employee
        this.conflictOptions = data.conflicts
        this.leavePlanOptions = data.leavePlan.leavePlanDetails
        this.isFetching = false
      })
    },

    async bootstrapModule(){
      this.isLoading = true
      if (this.$route.query.mode === 'admin') {
        await this.getAdminEmployeeAnnualLeave()
        this.isLoading = false
      }
      else {
        await this.getManagersEmployeeAnnualLeave("");
        this.isLoading = false
      }
    },
  },
  mounted(){
    this.leaveYear = this.$route.params.year
    this.planId = this.$route.params.id
    this.bootstrapModule()
  }

}
</script>

<style scoped>
::v-deep .select-class span select {
  margin-top: 3px !important;
  height: 42px !important;
}
.button-style {
  font-weight: 600 !important;
  font-size: 16px !important;
  font-size: 16px !important;
  line-height: 120% !important;
  padding: 10px 50px !important;
}
</style>